export default {
    methods: {
        validEmail(email) {
            var email_regx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            if (email.length <= 0) {
                return "email can't be empty"
            } else if (!email_regx.test(email)) {
                return "email incorrect"
            } else {
                return true
            }
        },
        validPhone(phone) {
            if (phone.length <= 0) {
                return "phone can't be empty"
            }
            // else if (phone.length != 10 || phone.indexOf("09") != 0) {
            //     return "phone"
            // } 
            else {
                return true
            }
        },
        validName(name) {
            // var name_regx = /[^\u4e00-\u9fa5]/
            if (name.length <= 0) {
                return "name can't be empty"
            }
            // else if (name_regx.test(name) || name.length > 5 || name.length < 2) {
            //     return "請填入五個字以內的中文姓名"
            // } 
            else {
                return true
            }
        },
        validAddress(address, city, area) {
            if (address.length < 4) {
                return "請填入正確的地址"
            } else if (city == '') {
                return "請選擇縣市"
            } else if (area == '') {
                return "請選擇地區"
            } else {
                return true
            }
        },
        validNotEmpty(data) {
            if (data.length <= 0) {
                return "this field can't be empty"
            } else {
                return true
            }
        },
        validOeto(city, area) {
            let name = this.CityConvert(city, area)
            let OetoCity = ["澎湖縣", "金門縣", "連江縣"]
            let OetoArea = ["蘭嶼鄉", "綠島鄉", "琉球鄉"]
            if (OetoCity.indexOf(name[0]) != -1) {
                return true
            } else if (OetoArea.indexOf(name[1]) != -1) {
                return true
            } else {
                return false
            }
        }
    }
};