<template>
  <div id="SendOrder">
    <div class="row">
      <div class="card_list">
        <div class="card">
          <div class="card_header">
            <span class="material-icons-two-tone"> category </span>
            <h4 class="sub_title_font">Products</h4>
          </div>
          <div class="card_body info">
            <div class="input_group col_12 products">
              <label class="sub_content_font product_title"
                >Product for inquiry</label
              >
              <template v-if="$store.state.products.length > 0">
                <p
                  class="content content_font"
                  v-for="item in now_shop_cart"
                  :key="`shopcart_order${item}`"
                >
                  <span>{{ GetProductTitle(item) }}</span>
                </p>
              </template>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card_header">
            <span class="material-icons-two-tone"> supervisor_account </span>
            <h4 class="sub_title_font">Contact Info</h4>
            <p class="sub_content_font">Require*</p>
          </div>
          <div class="card_body">
            <div class="input_group col_6">
              <label class="sub_content_font">Name*</label>
              <input
                type="text"
                :class="{ error: GetError('name') != '' }"
                v-model="form.name"
                name="name"
              />
              <p class="error">{{ GetError('name') }}</p>
            </div>
            <div class="input_group col_6">
              <label class="sub_content_font">Phone*</label>
              <input
                type="text"
                :class="{ error: GetError('phone') != '' }"
                v-model="form.phone"
                name="phone"
              />
              <p class="error">{{ GetError('phone') }}</p>
            </div>
            <div class="input_group col_12">
              <label class="sub_content_font">Email</label>
              <input type="text" v-model="form.email" name="email" />
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card_header">
            <span class="material-icons-two-tone"> insert_comment </span>
            <h4 class="sub_title_font">Message</h4>
          </div>
          <div class="card_body">
            <div class="input_group col_12">
              <label class="sub_content_font">Title</label>
              <p class="value">Product Inquiry</p>
            </div>
            <div class="input_group col_12">
              <label class="sub_content_font">Message</label>
              <textarea v-model="form.comment" name="comment"></textarea>
            </div>
          </div>
        </div>
      </div>
      <button class="submit_btn content_font" @click="Validate">SUBMIT</button>
    </div>
  </div>
</template>

<script>
import DataValid from '../common/data_valid';
import { GetMetaData } from '@/common/meta';
export default {
  name: 'SendOrder',
  mixins: [DataValid],
  data() {
    return {
      form: {
        name: '',
        phone: '',
        email: '',
        title: 'Product Inquiry',
        comment: '',
      },
      errors: [],
      meta_data: null,
    };
  },
  methods: {
    Validate() {
      this.errors = [];
      let valid_array = [
        {
          type: 'name',
          msg: this.validName(this.form.name),
        },
        {
          type: 'phone',
          msg: this.validPhone(this.form.phone),
        },
      ];

      valid_array.forEach((item) => {
        if (item.msg != true) {
          this.errors.push(item);
        }
      });

      if (this.errors.length <= 0 && this.now_shop_cart.length > 0) {
        this.SendOrder();
      }
    },
    async SendOrder() {
      this.$store.commit('SetLoading', true);
      let response = await this.SendData('/mail', 'post', {
        Data: {
          Name: this.form.name,
          Email: this.form.email,
          Phone: this.form.phone,
          Title: this.form.title,
          Content: this.form.comment,
          ProductID: this.now_shop_cart,
        },
      });
      if (response != 'error') {
        this.$store.commit('SetLoading', false);
        this.$store.commit('SetDialog', {
          status: true,
          content: 'Message have been sent',
        });
      }
    },
    GetError(val) {
      let error = this.errors.filter((item) => item.type == val);
      if (error.length > 0) {
        return error[0].msg;
      } else {
        return '';
      }
    },
    GetProductTitle(id) {
      let product = this.$store.state.products.filter(
        (item) => item.ProductID == id
      )[0];
      let series = this.$store.state.series.filter(
        (item) => item.ProductDirID == product.ProductDirID
      )[0];
      let category = this.$store.state.category.filter(
        (item) => item.ProductDir2ID == product.ProductDir2ID
      )[0];
      console.log(product, series, category);
      return category.Title + ' ' + series.Title + '-' + product.Title;
    },
  },
  created() {
    this.meta_data = GetMetaData('Product Inquiry', '', '');
  },
  computed: {
    now_shop_cart() {
      return this.$store.state.shop_cart;
    },
  },

  metaInfo() {
    return this.meta_data;
  },
};
</script>
